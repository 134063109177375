import React from "react";
import './Project.css'

import { useEffect, useRef } from "react";
import { motion, useInView, useAnimation, useIsPresent } from "framer-motion";


const Project_sqr = (props) => {
 

    //  Animation
    const ref = useRef(null);
    const isInView = useInView(ref, {once: true});

    const mainControls = useAnimation();

    useEffect(() => {if (isInView){
        mainControls.start("visible")
    }}, [isInView])
    //  Animation

    const defaultVariants  = {
      hidden: {opacity:0, y:25},
      visible: {opacity:1, y:0},
    };

    
  return (
    <>
      {props.spacing_init && <div className="spacing_init"></div>}
      {props.spacing_sqr_top && <div className="spacing_sqr_top"></div>}

      <motion.div className={'grid_container, project_slide'.replace(/,/g, ' ')}
            ref={ref}
            variants={defaultVariants}
            initial="hidden"
            animate={mainControls}
            transition={{duration:0.5, delay:0.2}}>

        <div className='project_sqr_a'>
          {/* <img src={props.image_a} className='project_image'></img> */}
            {props.image_a && (
              <img
                  src={props.image_a}
                  alt="Project visual"
                  className="project_image"
              />
            )}
            {props.video_a && (
              <video
                  src={props.video_a}
                  className="project_image"
                  controls={props.controls || false}
                  autoPlay={true}
                  loop={true}
                  muted={true}
                  webkit-playsinline={true}
              />
            )}
        </div>
        <div className='project_sqr_b'>
          {/* <img src={props.image_b} className='project_image'></img> */}
            {props.image_b && (
              <img
                  src={props.image_b}
                  alt="Project visual"
                  className="project_image"
              />
            )}
            {props.video_b && (
              <video
                  src={props.video_b}
                  className="project_image"
                  controls={props.controls || false}
                  autoPlay={true}
                  loop={true}
                  muted={true}
                  webkit-playsinline={true}
              />
            )}
        </div>
      </motion.div>

        {props.spacing_sqr_bottom && <div className="spacing_sqr_bottom"></div>}
        {props.spacing_last && <div className="spacing_last"></div>}
    </>
  );


}

export default Project_sqr