import React from "react";
import './Back.css'

import { useEffect, useRef } from "react";
import { motion, useInView, useAnimation, useIsPresent } from "framer-motion";

import { Link, Routes, Route } from "react-router-dom";

const Back = () => {
 

  //  Animation
  const ref = useRef(null);

  const mainControls = useAnimation();

  useEffect(() => {
    mainControls.start("visible");
  }, [mainControls]);
  //  Animation

  const defaultVariants  = {
      hidden: {opacity:0, y:25},
      visible: {opacity:1, y:0},
  }

  useEffect(() => {
    const handleScroll = () => {
      const element = ref.current;
      if (element) {
        if (window.scrollY >= window.innerHeight - 32) {
          element.classList.remove("back_top");
          element.classList.add("back_bottom");
        } else {
          element.classList.remove("back_bottom");
          element.classList.add("back_top");
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);


return (
  <>
    <motion.div
          variants={defaultVariants}
          initial="hidden"
          animate={mainControls}
          transition={{duration:0.5, delay:0.2}}>

        <Link to = {'/'}> 
        <div id="back" ref={ref} className="back back_top">Back</div>
        </Link>

      </motion.div>
    </>

  );

}

export default Back