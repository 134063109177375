import React from "react";
import { useState, useEffect, useRef } from "react";
import { motion, useInView, useAnimation, useIsPresent } from "framer-motion";

import Slider from "react-slick";

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import './Project.css'

import arrow_left_light from '../../../assets/arrow_left_light.svg'
import arrow_right_light from '../../../assets/arrow_right_light.svg'
import arrow_left_dark from '../../../assets/arrow_left_dark.svg'
import arrow_right_dark from '../../../assets/arrow_right_dark.svg'


const Project_slide = (props) => {
    
  const { slideData, dark = false } = props;
 
    const sliderRef = useRef(null);
    const leftControlRef = useRef(null);
    const rightControlRef = useRef(null);
    const [currentSlide, setCurrentSlide] = useState(0);


    const handleSlideClick = (direction) => {
      if (!sliderRef.current) return;
    
      const sliderNode = sliderRef.current.innerSlider.list;
      const length = sliderNode.children[0].children.length;

      const leftControl = leftControlRef.current;
      const rightControl = rightControlRef.current;
    
      if (direction === "left") {
        sliderRef.current.slickPrev(); // Go to the previous slide
    

        if (currentSlide === 1) {
          leftControl.style.opacity = "0.2";
        }


        if (currentSlide === length - 1) {
          rightControl.style.opacity = "1";
        }


      } else if (direction === "right") {
        sliderRef.current.slickNext(); // Go to the next slide
    

        if (currentSlide === 0) {
          leftControl.style.opacity = "1";
        }

        if (currentSlide === length - 2 || currentSlide === length - 1) {
          rightControl.style.opacity = "0.2";
        }else{
          rightControl.style.opacity = "1";
        }

      }
    
    };



    const settings = {
        focusOnSelect: false,
        focusOnChange: false,
        accessibility: true,

        arrows: false,
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: (slideIndex) => {
            setCurrentSlide(slideIndex);
        },
    };


      
    //  Animation
    const ref = useRef(null);
    const isInView = useInView(ref, {once: true});
    const mainControls = useAnimation();

    useEffect(() => {if (isInView){
        mainControls.start("visible")
    }}, [isInView])
    //  Animation
    

    const defaultVariants  = {
      hidden: {opacity:0, y:25},
      visible: {opacity:1, y:0},
    };


    // Determine arrow assets based on the color prop
    const arrowLeft = dark ? arrow_left_dark : arrow_left_light;
    const arrowRight = dark ? arrow_right_dark : arrow_right_light;


    return(
        <>
        {props.spacing_init && <div className="spacing_init"></div>}
        {props.spacing_sqr_top && <div className="spacing_sqr_top"></div>}

        <motion.div className= 'grid_container project_slide'
              ref={ref}
              variants={defaultVariants}
              initial="hidden"
              animate={mainControls}
              transition={{duration:0.5, delay:0.2}}>
  
  
                <div onClick={() => handleSlideClick("left")} ref={leftControlRef} className="left">
                  <img src={arrowLeft} alt="Left Arrow" />
                </div>
                <div onClick={() => handleSlideClick("right")} ref={rightControlRef} className="right">
                  <img src={arrowRight} alt="Right Arrow" />
                </div>


                <Slider {...settings} ref={sliderRef} className='project_rect'>
                    {slideData.map((slide, index) => (
                        <div key={index}>
                        {slide.video ? (
                          <video autoPlay muted loop webkit-playsinline playsInline
                            src={slide.video}
                            className= 'project_rect'
                            style={{ width: "100%"}}
                            controls={false}
                          />
                        ) : (
                          <img
                            src={slide.image}
                            className='project_rect'
                            style={{ width: "100%" }}
                          />
                        )}
                      </div>
                    ))}
                </Slider>
  
            </motion.div>

        {props.spacing_sqr_bottom && <div className="spacing_sqr_bottom"></div>}
        {props.spacing_last && <div className="spacing_last"></div>}
        </>

    )
}


export default Project_slide