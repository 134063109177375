import React from 'react';

import { useEffect } from "react";

import { Link, Routes, Route } from "react-router-dom";


// import '../Global/project.css'

import Section from '../Global/Section.js';

import Back from './Sub_component/Back.js'

import Project_intro from './Sub_component/Project_intro.js'
import Project_rect from './Sub_component/Project_rect.js'
import Project_sqr from './Sub_component/Project_sqr.js'
import Project_copy from './Sub_component/Project_copy.js'
import Project_caption from './Sub_component/Project_caption.js'
import Project_slide from './Sub_component/Project_slide.js'



import LG_cover from '../../assets/project/LG/LG_cover.jpg'

import LG_slide_1_a from '../../assets/project/LG/LG_slide_1_a.jpg'
import LG_slide_1_b from '../../assets/project/LG/LG_slide_1_b.jpg'

import LG_slide_2 from '../../assets/project/LG/LG_slide_2.mp4'

import LG_slide_3_a from '../../assets/project/LG/LG_slide_3_a.jpg'
import LG_slide_3_b from '../../assets/project/LG/LG_slide_3_b.jpg'

import LG_slide_4_a from '../../assets/project/LG/LG_slide_4_a.jpg'
import LG_slide_4_b from '../../assets/project/LG/LG_slide_4_b.jpg'
import LG_slide_4_c from '../../assets/project/LG/LG_slide_4_c.jpg'
import LG_slide_4_d from '../../assets/project/LG/LG_slide_4_d.jpg'

import LG_slide_5_a from '../../assets/project/LG/LG_slide_5_a.jpg'
import LG_slide_5_b from '../../assets/project/LG/LG_slide_5_b.jpg'
import LG_slide_5_c from '../../assets/project/LG/LG_slide_5_c.jpg'

import LG_slide_6 from '../../assets/project/LG/LG_slide_6.jpg'


import Moderna_cover from '../../assets/project/Moderna/Moderna_cover.jpg'


const LG_slide_4 = [
  { image: LG_slide_4_a },
  { image: LG_slide_4_b },
  { image: LG_slide_4_c },
  { image: LG_slide_4_d },
];

const LG_slide_5 = [
  { image: LG_slide_5_a },
  { image: LG_slide_5_b },
  { image: LG_slide_5_c },
];

const LG = (props) => {
  
    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])

  return (
    <>
      <div className='intro_background'></div>
      <Back></Back>

      <div className='project_con'>

      <Project_intro title = "LG" name = "LG TV" copy = "Content consumption trends shifted dramatically during the pandemic. Our goal was to design an experience that anticipates the evolving role of TVs in homes, emphasizing their social aspect and shaping the future “new normal” of television viewing."></Project_intro>

        <Project_rect image = {LG_cover} spacing_init={true} spacing_last={true}></Project_rect>
        <Project_sqr image_a = {LG_slide_1_a} image_b = {LG_slide_1_b} spacing_init={false} spacing_last={false} spacing_sqr_top={true} spacing_sqr_bottom={true}></Project_sqr>
        <Project_copy spacing_init_copy = {true} spacing_last = {true} copy = "We came up with the new native app, Watch Party, which is a social TV experience that enables synchronized viewing and real-time interaction, connecting users for a shared entertainment experience."></Project_copy>


        <Project_rect video = {LG_slide_2} spacing_init={true} spacing_last={false}></Project_rect>
        <Project_sqr image_a = {LG_slide_3_a} image_b = {LG_slide_3_b} spacing_init={false} spacing_last={false} spacing_sqr_top={true} spacing_sqr_bottom={true}></Project_sqr>
        <Project_copy spacing_init_copy = {true} spacing_last = {true} copy = "Within the Watch Party app, users can see who’s watching together in real time, browse and join live public watch parties, and explore recommended contents by friends."></Project_copy>

        <Project_slide dark={false} slideData={LG_slide_4} spacing_init={true} spacing_last={false} spacing_sqr_top={true} spacing_sqr_bottom={true}></Project_slide>
        <Project_copy spacing_init_copy = {true} spacing_last = {true} copy = "The Watching Together experience includes real-time reactions, allowing users to see and engage with each other's responses."></Project_copy>
        
        <Project_slide dark={false} slideData={LG_slide_5} spacing_init={true} spacing_last={false} spacing_sqr_top={true} spacing_sqr_bottom={true}></Project_slide>
        <Project_caption spacing_init_copy = {true} spacing_last = {true} caption = "Real-time reaction features in public watch parties"></Project_caption>
        
        <Project_rect image = {LG_slide_6} spacing_init={false} spacing_last={false}></Project_rect>
        <Project_copy spacing_init_copy = {true} spacing_last = {true} copy = "We also designed the system UI for the overall experience."></Project_copy>


        <Section title="Team members" copy="">
            <div className='list_element'>Thadeu Morgado, Jon Stookey, Jenny Hirsch, Daniel Kusaka, and Antoine Vidal</div>
        </Section>

        <Section title="Next Project" copy="">
          <Link className="card" to = {'/Moderna'}> 
            <div className='list_element'>
            <img src={Moderna_cover} className='project_image'></img></div>
            <div className='list_element'>Moderna</div>
            <div className='list_element secondary'>Pitch Concept</div>
          </Link>
        </Section>
          
      <Section title="" copy="© 2025"/>
      </div>
    </>
  );
};

export default LG;