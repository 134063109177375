import React from "react";
import { useEffect, useRef } from "react";

import { motion, useInView, useAnimation } from "framer-motion";
import { Link } from "react-router-dom";

import './Home_projects_cover.css'


const Home_project_cover = (props) => {
    
  const { cover, client, title, to, className } = props;

    //  Animation
    const ref = useRef(null);
    const isInView = useInView(ref, {once: true});

    const mainControls = useAnimation();

    useEffect(() => {if (isInView){
        mainControls.start("visible")
    }}, [isInView])
    //  Animation

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    return(
        <>
            <motion.div className={`slide ${className || ""}`.trim()}
                    ref={ref}
                    variants={{
                        hidden: {opacity:0, y:25},
                        visible: {opacity:1, y:0}
                    }}
                    initial="hidden"
                    animate={mainControls}
                    transition={{duration:0.5, delay:0.2}}>

            <Link className="card" to = {to}>
                                    
                <img src={cover} style={{ width: "100%" }}></img>

                <div className="info_con">
                    <div className="client_name">{client}</div>
                    <div className="project_name secondary">{title}</div>
                </div>

                </Link>
            </motion.div>


        </>
    )
}


export default Home_project_cover