import React from 'react';

import { useEffect } from "react";

import { Link, Routes, Route } from "react-router-dom";


// import '../Global/project.css'

import Section from '../Global/Section.js';

import Back from './Sub_component/Back.js'

import Project_intro from './Sub_component/Project_intro.js'
import Project_rect from './Sub_component/Project_rect.js'
import Project_sqr from './Sub_component/Project_sqr.js'
import Project_copy from './Sub_component/Project_copy.js'
import Project_caption from './Sub_component/Project_caption.js'
import Project_slide from './Sub_component/Project_slide.js'



import Amazon_cover from '../../assets/project/Amazon/Amazon_cover.jpg'
import Amazon_slide_1 from '../../assets/project/Amazon/Amazon_slide_1.jpg'
import Amazon_slide_2 from '../../assets/project/Amazon/Amazon_slide_2.mp4'
import Amazon_slide_3 from '../../assets/project/Amazon/Amazon_slide_3.jpg'
import Amazon_slide_4_a from '../../assets/project/Amazon/Amazon_slide_4_a.jpg'
import Amazon_slide_4_b from '../../assets/project/Amazon/Amazon_slide_4_b.jpg'
import Amazon_slide_5 from '../../assets/project/Amazon/Amazon_slide_5.mp4'
import Amazon_slide_6_a from '../../assets/project/Amazon/Amazon_slide_6_a.jpg'
import Amazon_slide_6_b from '../../assets/project/Amazon/Amazon_slide_6_b.jpg'
import Amazon_slide_7_a from '../../assets/project/Amazon/Amazon_slide_7_a.jpg'
import Amazon_slide_7_b from '../../assets/project/Amazon/Amazon_slide_7_b.jpg'
import Amazon_slide_7_c from '../../assets/project/Amazon/Amazon_slide_7_c.jpg'

import LG_cover from '../../assets/project/LG/LG_cover.jpg'

const Amazon_slide_7 = [
  { image: Amazon_slide_7_a },
  { image: Amazon_slide_7_b },
  { image: Amazon_slide_7_c },
];

const Amazon = (props) => {
  
    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])

  return (
    <>
      <div className='intro_background'></div>
      <Back></Back>

      <div className='project_con'>

      <Project_intro title = "Amazon" name = "Amazon Kids" copy = "Amazon has a tablet device designed for kids (6-12) to safely learn and grow with it. This project was aim to craft a vision for this product by embodying a universal experience that allows more simple, fun and engaging journey for kids."></Project_intro>

        <Project_rect image = {Amazon_cover} spacing_init={true} spacing_last={true}></Project_rect>

        <Project_rect image = {Amazon_slide_1} spacing_init={false} spacing_last={false}></Project_rect>
        <Project_caption spacing_init_copy = {true} spacing_last = {true} caption = "Amazon Kids Fire Tablet with a legacy UI system"></Project_caption>

        <Project_rect video = {Amazon_slide_2} spacing_init={false} spacing_last={false}></Project_rect>
        <Project_copy spacing_init_copy = {true} spacing_last = {true} copy = "Simple navigation in the main page allows parents and kids to easily begin their journey by discovering recently watched, allow-listed, and new contents."></Project_copy>

        <Project_rect image = {Amazon_slide_3} spacing_init={false} spacing_last={false}></Project_rect>
        <Project_sqr image_a = {Amazon_slide_4_a} image_b = {Amazon_slide_4_b} spacing_init={false} spacing_last={false} spacing_sqr_top={true} spacing_sqr_bottom={true}></Project_sqr>
        <Project_copy spacing_init_copy = {true} spacing_last = {true} copy = "Highly visual interface with clear hierarchy also enhances discoverability of contents, especially making the browsing easier for kids."></Project_copy>

        <Project_rect video = {Amazon_slide_5} spacing_init={false} spacing_last={false}></Project_rect>
        <Project_sqr image_a = {Amazon_slide_6_a} image_b = {Amazon_slide_6_b} spacing_init={false} spacing_last={false} spacing_sqr_top={true} spacing_sqr_bottom={true}></Project_sqr>
        <Project_copy spacing_init_copy = {true} spacing_last = {true} copy = "For the kids product, it is important to monitor the time of usage. With dedicated control panel, parents will be able to switch profiles and manage the device better to ensure the safe use of digital device for kids."></Project_copy>

        <Project_slide dark={false} slideData={Amazon_slide_7} spacing_init={true} spacing_last={false} spacing_sqr_top={true} spacing_sqr_bottom={true}></Project_slide>
        <Project_copy spacing_init_copy = {true} spacing_last = {true} copy = "With its mission to grow with kids, the product evolves with the child’s age, adjusting its size and interface to match their expecatations, ensuring a personalized and age-appropriate experience."></Project_copy>

        <Section title="Team members" copy="">
            <div className='list_element'>Brett Bergeron, Amber Ward, Chaz Rini, Mansi Soni, and Alice Lee</div>
        </Section>

        <Section title="Next Project" copy="">
          <Link className="card" to = {'/LG'}> 
            <div className='list_element'>
            <img src={LG_cover} className='project_image'></img></div>
            <div className='list_element'>LG</div>
            <div className='list_element secondary'>LG TV</div>
          </Link>
        </Section>
          
      <Section title="" copy="© 2025"/>
      </div>
    </>
  );
};

export default Amazon;