import React from "react";
import './Project.css'

import { useEffect, useRef } from "react";
import { motion, useInView, useAnimation, useIsPresent } from "framer-motion";


const Project_copy = (props) => {
 

    //  Animation
    const ref = useRef(null);
    const isInView = useInView(ref, {once: true});

    const mainControls = useAnimation();

    useEffect(() => {if (isInView){
        mainControls.start("visible")
    }}, [isInView])
    //  Animation

    const defaultVariants  = {
      hidden: {opacity:0, y:25},
      visible: {opacity:1, y:0},
    };


  return (
    <>
      {props.spacing_init_copy && <div className="spacing_init_copy"></div>}
      <motion.div className='grid_container project_slide'
            ref={ref}
            variants={defaultVariants}
            initial="hidden"
            animate={mainControls}
            transition={{duration:0.5, delay:0.2}}>
        <div className="copy project_des">
          {props.copy}
        </div>
      </motion.div>
      {props.spacing_last && <div className="spacing_last"></div>}
    </>
  );

}

export default Project_copy