import React from "react";

import '../Global/normalize.css'
import '../Global/global.css'
import '../Global/global_1280.css'
import '../Global/global_768.css'

import Section from '../Global/Section.js';

import Home_intro from './Home_intro.js';
import Home_projects from './Home_projects.js';


const Home_con = () => {

    return(
        <>
        <Home_intro/>
        <Home_projects/>
        <Section title="Selected clients" copy="">
          <div className='list_element'>Tiktok</div>
          <div className='list_element'>UnitedHealthcare</div>
          <div className='list_element'>DirecTV</div>
          <div className='list_element'>Amazon</div>
          <div className='list_element'>Vistaprint</div>
          <div className='list_element'>Goldman Sachs</div>
          <div className='list_element'>LG</div>
        </Section>
        <Section title="Experience" copy="">
          {/* <div className='list_container'> */}
            <div className='list'>
              <div className='list_element'>Ongoing</div>
              <div className='list_element'>Freelance Design</div>
            </div>
            <div className='list'>
              <div className='list_element'>20’ Oct – 23’ July</div>
              <div className='list_element'>Work & Co</div>
              <div className='list_element'>Designer – Senior Designer</div>
            </div>
            <div className='list'>
              <div className='list_element'>20’ Mar – June</div>
              <div className='list_element'>Wkshps</div>
              <div className='list_element'>Design Intern</div>
            </div>
            <div className='list'>
              <div className='list_element'>19’ Oct – 20’ Jan</div>
              <div className='list_element'>Order</div>
              <div className='list_element'>Design Intern</div>
            </div>
            <div className='list'>
              <div className='list_element'>18’ Oct – Dec</div>
              <div className='list_element'>Figur8</div>
              <div className='list_element'>Product Design Intern</div>
            </div>
            {/* <div className='list'> */}
              <div className='list_element'>18’ June – Aug</div>
              <div className='list_element'>Doberman</div>
              <div className='list_element'>Design Intern</div>
            {/* </div> */}
          {/* </div> */}
        </Section>
        <Section title="Recognition" copy="Type Directors Club, Art Directors Club, Fast Company, Indigo Design, and Typeforce"/>
        <Section title="Education" copy="">
          {/* <div className='list_container'> */}
            <div className='list'>
              <div className='list_element'>23’ Oct – 24’ Sept</div>
              <div className='list_element'>Type@Cooper</div>
              <div className='list_element'>Extended Program</div>
            </div>
            {/* <div className='list'> */}
              <div className='list_element'>13’ Aug – 19’ June</div>
              <div className='list_element'>Rhode Island School of Design</div>
              <div className='list_element'>BFA Graphic Design</div>
            {/* </div> */}
          {/* </div> */}
        </Section>
        <Section title="Contact" copy="">
          <div className='list_element'><a href="mailto: hello@hnrysng.com">hello@hnrysng.com</a></div>
          <div className='list_element'><a href="https://www.instagram.com/hnrysng/" target="_blank">Instagram</a></div>
          <div className='list_element'><a href="https://www.linkedin.com/in/hyeong-geun-henry-song-2a4359135/" target="_blank">Linkedin</a></div>
        </Section>
        <Section title="" copy="© 2025"/>
        </>
    )
}


export default Home_con