import React from 'react';

import { useEffect } from "react";

import { Link, Routes, Route } from "react-router-dom";


// import '../Global/project.css'

import Section from '../Global/Section.js';

import Back from './Sub_component/Back.js'

import Project_intro from './Sub_component/Project_intro.js'
import Project_rect from './Sub_component/Project_rect.js'
import Project_sqr from './Sub_component/Project_sqr.js'
import Project_copy from './Sub_component/Project_copy.js'
import Project_caption from './Sub_component/Project_caption.js'
import Project_slide from './Sub_component/Project_slide.js'



import Vistaprint_cover from '../../assets/project/Vistaprint/Vistaprint_cover.jpg'
import Vistaprint_slide_1 from '../../assets/project/Vistaprint/Vistaprint_slide_1.jpg'
import Vistaprint_slide_2 from '../../assets/project/Vistaprint/Vistaprint_slide_2.jpg'
import Vistaprint_slide_3 from '../../assets/project/Vistaprint/Vistaprint_slide_3.mp4'
import Vistaprint_slide_4 from '../../assets/project/Vistaprint/Vistaprint_slide_4.jpg'
import Vistaprint_slide_5_a from '../../assets/project/Vistaprint/Vistaprint_slide_5_a.jpg'
import Vistaprint_slide_5_b from '../../assets/project/Vistaprint/Vistaprint_slide_5_b.jpg'
import Vistaprint_slide_6 from '../../assets/project/Vistaprint/Vistaprint_slide_6.jpg'
import Vistaprint_slide_7 from '../../assets/project/Vistaprint/Vistaprint_slide_7.jpg'
import Vistaprint_slide_8 from '../../assets/project/Vistaprint/Vistaprint_slide_8.jpg'

import Amazon_cover from '../../assets/project/Amazon/Amazon_cover.jpg'



const Vistaprint = (props) => {
  
    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])

  return (
    <>
      <div className='intro_background'></div>
      <Back></Back>

      <div className='project_con'>

      <Project_intro title = "Vistaprint" name = "Mailing Service" copy = "Vistaprint offers printing services for small businesses and individuals. They came up with a new feature, Mailing Service, to ship products directly to customers, removing the need for self-shipping. This project was aim to seamlessly integrate new feature into the existing user flow."></Project_intro>

        <Project_rect image = {Vistaprint_cover} spacing_init={true} spacing_last={true}></Project_rect>
        <Project_rect image = {Vistaprint_slide_1} spacing_init={false} spacing_last={false}></Project_rect>
        <Project_copy spacing_init_copy = {true} spacing_last = {true} copy = "With small business owners as main users, Vistaprint offers over 1,000 products, with users often ordering more than 500 items at once. To reduce the burden of shipping the large amount of prints, the Mailing Service feature directly shipping the products for the core customers."></Project_copy>
        <Project_rect image = {Vistaprint_slide_2} spacing_init={false} spacing_last={false}></Project_rect>
        <Project_copy spacing_init_copy = {true} spacing_last = {true} copy = "Mailing Service features comes after user finishes design customization. This is the moment we introduce Mailing Service, when the user mindset shifts from creative to more utilitarian."></Project_copy>
        <Project_rect video = {Vistaprint_slide_3} spacing_init={false} spacing_last={false}></Project_rect>
        <Project_copy spacing_init_copy = {true} spacing_last = {true} copy = "Business owners typically store customer lists as files. To streamline the process, we added a step allowing them to upload these files directly, eliminating the need for manual entry."></Project_copy>
        <Project_rect image = {Vistaprint_slide_4} spacing_init={false} spacing_last={false}></Project_rect>
        <Project_sqr image_a = {Vistaprint_slide_5_a} image_b = {Vistaprint_slide_5_b} spacing_init={false} spacing_last={false} spacing_sqr_top={true} spacing_sqr_bottom={true}></Project_sqr>
        <Project_copy spacing_init_copy = {true} spacing_last = {true} copy = "When dealing with hundreds of addresses, small errors tend to appear. Two options are offered to solve this: manually adjust the list or use automatic corrections."></Project_copy>
        <Project_rect image = {Vistaprint_slide_6} spacing_init={false} spacing_last={false}></Project_rect>
        <Project_copy spacing_init_copy = {true} spacing_last = {true} copy = "If choose to edit manually, users get a tool that simplifies the process of managing the list, making it easy to edit, delete, and merge addresses when needed."></Project_copy>
        <Project_rect image = {Vistaprint_slide_7} spacing_init={false} spacing_last={false}></Project_rect>
        <Project_copy spacing_init_copy = {true} spacing_last = {true} copy = "Before completing the order, users can also include themselves in the mailing list for keeping the product as record."></Project_copy>
        <Project_rect image = {Vistaprint_slide_8} spacing_init={false} spacing_last={false}></Project_rect>
        <Project_copy spacing_init_copy = {true} spacing_last = {true} copy = "We also included a Mailing Service overview on the product detail page to increase the exposure of new feature’s launch."></Project_copy>



        <Section title="Team members" copy="">
            <div className='list_element'>Fabricio Teixeira, Samantha Bearden, Erin Leforce, Shannen Craft, and Vitor Romano</div>
        </Section>

        <Section title="Next Project" copy="">
          <Link className="card" to = {'/Amazon'}> 
            <div className='list_element'>
            <img src={Amazon_cover} className='project_image'></img></div>
            <div className='list_element'>Amazon</div>
            <div className='list_element secondary'>Amazon Kids</div>
          </Link>
        </Section>
          
      <Section title="" copy="© 2025"/>
      </div>
    </>
  );
};

export default Vistaprint;