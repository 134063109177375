import './App.css';
import { useLocation } from "react-router-dom";
import { HashRouter as Router, Routes, Route } from "react-router-dom";


import './components/Global/normalize.css'
import './components/Global/global.css'
import './components/Global/global_1280.css'
import './components/Global/global_768.css'


import Section from './components/Global/Section.js';

import Home_con from './components/Home/Home_con.js';

import UHC from "./components/Projects/UHC.js";
import Vistaprint from "./components/Projects/Vistaprint.js";
import Amazon from "./components/Projects/Amazon.js";
import LG from "./components/Projects/LG.js";
import Moderna from "./components/Projects/Moderna.js";




function App() {


  const location = useLocation();
  console.log('This site is built with ReactJS')

  return (
    <div className="App">


        <Routes location={location} key={location.pathname}>
          <Route index element={<Home_con />} />
          <Route path="/UHC" element={<UHC />} />
          <Route path="/Vistaprint" element={<Vistaprint />} />
          <Route path="/Amazon" element={<Amazon />} />
          <Route path="/LG" element={<LG />} />
          <Route path="/Moderna" element={<Moderna />} />
        </Routes>


    </div>
  );
}

export default App;
