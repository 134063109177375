import React from 'react';

import { useEffect } from "react";

import { Link, Routes, Route } from "react-router-dom";


// import '../Global/project.css'

import Section from '../Global/Section.js';

import Back from './Sub_component/Back.js'

import Project_intro from './Sub_component/Project_intro.js'
import Project_rect from './Sub_component/Project_rect.js'
import Project_sqr from './Sub_component/Project_sqr.js'
import Project_copy from './Sub_component/Project_copy.js'
import Project_caption from './Sub_component/Project_caption.js'
import Project_slide from './Sub_component/Project_slide.js'



import Moderna_cover from '../../assets/project/Moderna/Moderna_cover.jpg'
import Moderna_slide_1 from '../../assets/project/Moderna/Moderna_slide_1.jpg'
import Moderna_slide_2 from '../../assets/project/Moderna/Moderna_slide_2.jpg'
import Moderna_slide_3 from '../../assets/project/Moderna/Moderna_slide_3.jpg'

import Moderna_slide_4_a from '../../assets/project/Moderna/Moderna_slide_4_a.jpg'
import Moderna_slide_4_b from '../../assets/project/Moderna/Moderna_slide_4_b.jpg'

import Moderna_slide_5_a from '../../assets/project/Moderna/Moderna_slide_5_a.jpg'
import Moderna_slide_5_b from '../../assets/project/Moderna/Moderna_slide_5_b.jpg'
import Moderna_slide_5_c from '../../assets/project/Moderna/Moderna_slide_5_c.jpg'

import Moderna_slide_6_a from '../../assets/project/Moderna/Moderna_slide_6_a.jpg'
import Moderna_slide_6_b from '../../assets/project/Moderna/Moderna_slide_6_b.jpg'
import Moderna_slide_6_c from '../../assets/project/Moderna/Moderna_slide_6_c.jpg'

import Moderna_slide_7 from '../../assets/project/Moderna/Moderna_slide_7.jpg'


import UHC_cover from '../../assets/project/UnitedHealthcare/UHC_cover.jpg'


const Moderna_slide_4 = [
  { image: Moderna_slide_4_a },
  { image: Moderna_slide_4_b },
];

const Moderna_slide_5 = [
  { image: Moderna_slide_5_a },
  { image: Moderna_slide_5_b },
  { image: Moderna_slide_5_c },
];

const Moderna_slide_6 = [
  { image: Moderna_slide_6_a },
  { image: Moderna_slide_6_b },
  { image: Moderna_slide_6_c },
];


const Moderna = (props) => {
  
    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])

  return (
    <>
      <div className='intro_background'></div>
      <Back></Back>

      <div className='project_con'>

      <Project_intro title = "Moderna" name = "Pitch Concept" copy = "Moderna is a biotechnology company pioneering mRNA-based medicines and vaccines to treat and prevent diseases. This project reimagines modernatx.com to support Moderna’s identity as a tech-driven innovator rather than a traditional pharmaceutical firm, while delivering health related information in easily accessible ways."></Project_intro>

        <Project_rect image = {Moderna_cover} spacing_init={true} spacing_last={true}></Project_rect>

        <Project_rect image = {Moderna_slide_1} spacing_init={false} spacing_last={false}></Project_rect>
        <Project_caption spacing_init_copy = {true} spacing_last = {true} caption = "Current website works as a resource center for the professionals."></Project_caption>

        <Project_rect image = {Moderna_slide_2} spacing_init={false} spacing_last={false}></Project_rect>
        <Project_copy spacing_init_copy = {true} spacing_last = {true} copy = "We started by designing a set of interfaces to shape the website’s look and feel that reinforces Moderna’s identity as a tech-driven company. By leveraging a design system and flexible technology, we transformed a static resource center into a dynamic tool that involves scalable platform rather than just a website."></Project_copy>
        <Project_rect image = {Moderna_slide_3} spacing_init={false} spacing_last={false}></Project_rect>
        <Project_copy spacing_init_copy = {true} spacing_last = {true} copy = "As the interest in vaccine products has grown after pandemic, it was important to have clear communication on vaccine product sites to broad audience of the page. Clear visual communication ensures that both professionals and the public can easily understand and engage with the information."></Project_copy>
        
        <Project_slide dark={true} slideData={Moderna_slide_4} spacing_init={true} spacing_last={false} spacing_sqr_top={true} spacing_sqr_bottom={true}></Project_slide>
        <Project_copy spacing_init_copy = {true} spacing_last = {true} copy = "Aligned with the company’s ethos, the site functions like a tech platform—not just providing information but also equipping users with tools to manage and organize their resources."></Project_copy>

        <Project_slide dark={true} slideData={Moderna_slide_5} spacing_init={true} spacing_last={false} spacing_sqr_top={false} spacing_sqr_bottom={false}></Project_slide>
        <Project_copy spacing_init_copy = {true} spacing_last = {true} copy = "For art direction, we tried to reflect what the company truly aims to achieve—prioritizing warmth and human connection over a purely scientific, pharmaceutical feel. "></Project_copy>

        <Project_slide dark={true} slideData={Moderna_slide_6} spacing_init={true} spacing_last={false} spacing_sqr_top={false} spacing_sqr_bottom={false}></Project_slide>
        <Project_copy spacing_init_copy = {true} spacing_last = {true} copy = "One of the key part that emphasized the human connection was the voice of people. For those considering joining the company, we showcased the work experience, culture and values of company through the voice of employees."></Project_copy>

        <Project_rect image = {Moderna_slide_7} spacing_init={false} spacing_last={true}></Project_rect>


        <Section title="Team members" copy="">
            <div className='list_element'>Casey Sheehan, Eric Bee, Chenta Yu, and Julia Albuquerque</div>
        </Section>

        <Section title="Next Project" copy="">
          <Link className="card" to = {'/UHC'}> 
            <div className='list_element'>
            <img src={UHC_cover} className='project_image'></img></div>
            <div className='list_element'>United Healthcare</div>
            <div className='list_element secondary'>Onboarding</div>
          </Link>
        </Section>
          
      <Section title="" copy="© 2025"/>
      </div>
    </>
  );
};

export default Moderna;