import React from "react";
import './Project.css'

import { useEffect, useRef } from "react";
import { motion, useInView, useAnimation, useIsPresent } from "framer-motion";


const Project_intro = (props) => {
 

    //  Animation
    const ref = useRef(null);
    const isInView = useInView(ref, {once: true});

    const mainControls = useAnimation();

    useEffect(() => {if (isInView){
        mainControls.start("visible")
    }}, [isInView])
    //  Animation

    const defaultVariants  = {
      hidden: {opacity:0, y:25},
      visible: {opacity:1, y:0},
    };

  return (
    <>
      <motion.div className='grid_container project_intro'
            ref={ref}
            variants={defaultVariants}
            initial="hidden"
            animate={mainControls}
            transition={{duration:0.5, delay:0.2}}>
        <div className='title'>
          <div className='project_title'>
            {props.title}
          </div>
          <div className='project_title project_name secondary'>
            {props.name}
          </div>
        </div>
        <div className='copy'>
          {props.copy}
        </div>
      </motion.div>
    </>
  );

}

export default Project_intro