import React from 'react';

import { useEffect } from "react";

import { Link, Routes, Route } from "react-router-dom";


// import '../Global/project.css'

import Section from '../Global/Section.js';

import Back from './Sub_component/Back.js'

import Project_intro from './Sub_component/Project_intro.js'
import Project_rect from './Sub_component/Project_rect.js'
import Project_sqr from './Sub_component/Project_sqr.js'
import Project_copy from './Sub_component/Project_copy.js'
import Project_caption from './Sub_component/Project_caption.js'
import Project_slide from './Sub_component/Project_slide.js'



import UHC_cover from '../../assets/project/UnitedHealthcare/UHC_cover.jpg'
import UHC_slide_1 from '../../assets/project/UnitedHealthcare/UHC_slide_1.jpg'
import UHC_slide_2_a from '../../assets/project/UnitedHealthcare/UHC_slide_2_a.jpg'
import UHC_slide_2_b from '../../assets/project/UnitedHealthcare/UHC_slide_2_b.mp4'
import UHC_slide_3 from '../../assets/project/UnitedHealthcare/UHC_slide_3.jpg'
import UHC_slide_4 from '../../assets/project/UnitedHealthcare/UHC_slide_4.mp4'
import UHC_slide_5_a from '../../assets/project/UnitedHealthcare/UHC_slide_5_a.mp4'
import UHC_slide_5_b from '../../assets/project/UnitedHealthcare/UHC_slide_5_b.jpg'
import UHC_slide_6 from '../../assets/project/UnitedHealthcare/UHC_slide_6.jpg'
import UHC_slide_7 from '../../assets/project/UnitedHealthcare/UHC_slide_7.jpg'
import UHC_slide_8 from '../../assets/project/UnitedHealthcare/UHC_slide_8.jpg'


import Vistaprint_cover from '../../assets/project/Vistaprint/Vistaprint_cover.jpg'


const UHC = (props) => {
  
    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])

  return (
    <>
      <div className='intro_background'></div>
      <Back></Back>

      <div className='project_con'>

      <Project_intro title = "United Healthcare" name = "Onboarding" copy = "While UnitedHealthcare is the largest insurance company, there has been a major number of user-drop happening during the onboarding process. With the aim of preventing the drop, the design focused on creating seamless onboarding experience, personalization, accessibility, and visual clarity to help new members feel supported, informed, and confident as they navigated their healthcare journey."></Project_intro>

        <Project_rect image = {UHC_cover} spacing_init={true} spacing_last={true}></Project_rect>

        <Project_rect image = {UHC_slide_1} spacing_init={false} spacing_last={false} caption="asdfjalksdf"></Project_rect>
        <Project_caption spacing_init_copy = {true} spacing_last = {false} caption = "Previous tasks on the dashboard"></Project_caption>
        <Project_copy spacing_init_copy = {true} spacing_last = {true} copy = "This project began with identifying key tasks users need to complete on the dashboard. We noticed inconsistencies in the visual structure across tasks, making it difficult for users to quickly determine priorities."></Project_copy>

        <Project_sqr image_a = {UHC_slide_2_a} video_b = {UHC_slide_2_b} spacing_init={false} spacing_last={false} spacing_sqr_top={true} spacing_sqr_bottom={true}></Project_sqr>
        <Project_rect image = {UHC_slide_3} spacing_init={false} spacing_last={false}></Project_rect>
        <Project_copy spacing_init_copy = {true} spacing_last = {true} copy = "To serve UHC’s diverse user base, from young adults to seniors, we standardized visual for task tiles. The actionable copy, unique iconography and variations of brand colors allowed tasks to be more scannable and easy to digest for users."></Project_copy>
        
        <Project_rect video = {UHC_slide_4} spacing_init={false} spacing_last={false}></Project_rect>
        <Project_copy spacing_init_copy = {true} spacing_last = {true} copy = "Clear and consistent visuals are the key for seamless task flow. By reskinning the task to match the look of its tile, we achieved cohesive look and feel throughout the experience."></Project_copy>
        
        <Project_sqr video_a = {UHC_slide_5_a} image_b = {UHC_slide_5_b} spacing_init={false} spacing_last={false} spacing_sqr_top={true} spacing_sqr_bottom={true}></Project_sqr>
        <Project_rect image = {UHC_slide_6} spacing_init={false} spacing_last={false}></Project_rect>
        <Project_copy spacing_init_copy = {true} spacing_last = {true} copy = "It is known that once a user leaves, it’s 60% likely that they will never come back. So, we wanted to keep the momentum as much as possible by celebrating enrollment, cheering throughout the tasks, and providing the next task in continuous flow."></Project_copy>
        
        <Project_rect image = {UHC_slide_7} spacing_init={false} spacing_last={false}></Project_rect>
        <Project_caption spacing_init_copy = {true} spacing_last = {true} caption = "Tile component to hand off to the team as a part of design system"></Project_caption>
        
        <Project_rect image = {UHC_slide_8} spacing_init={false} spacing_last={false}></Project_rect>
        <Project_copy spacing_init_copy = {true} spacing_last = {true} copy = "This entire experience was scaled and adapted for various digital touch points — from a responsive website to a mobile."></Project_copy>


        <Section title="Team members" copy="">
            <div className='list_element'>Derek Fridman, Tiffanie Terry, Samantha Bearden, Lívia Ito, Renan Feltri, and Michell Liv</div>
        </Section>

        <Section title="Next Project" copy="">
          <Link className="card" to = {'/Vistaprint'}> 
            <div className='list_element'>
            <img src={Vistaprint_cover} className='project_image'></img></div>
            <div className='list_element'>Vistaprint</div>
            <div className='list_element secondary'>Mailing Service</div>
          </Link>
        </Section>
          
      <Section title="" copy="© 2025"/>
      </div>
    </>
  );
};

export default UHC;