import React from "react";
import { useState } from "react";

import { useEffect, useRef } from "react";
import { motion, useInView, useAnimation, useIsPresent } from "framer-motion";

import UHC_cover from '../../assets/project/UnitedHealthcare/UHC_cover.jpg'
import Vistaprint_cover from '../../assets/project/Vistaprint/Vistaprint_cover.jpg'
import Amazon_cover from '../../assets/project/Amazon/Amazon_cover.jpg'
import LG_cover from '../../assets/project/LG/LG_cover.jpg'
import Moderna_cover from '../../assets/project/Moderna/Moderna_cover.jpg'

import Home_project_cover from './Home_project_cover.js'





const Home_projects = () => {

    //  Tabs
    const [ToggleState, setToggleState] = useState('product');

    const toggleTab = (index) => {
        setToggleState(index);
    }
    //  Tabs

    //  Animation
    const ref = useRef(null);
    const isInView = useInView(ref, {once: true});

    const mainControls = useAnimation();

    useEffect(() => {if (isInView){
        mainControls.start("visible")
    }}, [isInView])
    //  Animation

    return(
        <>

            <div className="section_container section_margin">
                <motion.div className="grid_container"
                    ref={ref}
                    variants={{
                        hidden: {opacity:0, y:25},
                        visible: {opacity:1, y:0}
                    }}
                    initial="hidden"
                    animate={mainControls}
                    transition={{duration:0.5, delay:0.2}}>

                    <div className="title col_1_4">Projects</div>

                <Home_project_cover to = {'/UHC'} cover={UHC_cover} client = 'UnitedHealthcare' title = 'Onboarding'></Home_project_cover>
                <Home_project_cover to = {'/Vistaprint'} cover={Vistaprint_cover} client = 'Vistaprint' title = 'Mailing Service'></Home_project_cover>
                <Home_project_cover to = {'/Amazon'} cover={Amazon_cover} client = 'Amazon' title = 'Amazon Kids'></Home_project_cover>
                <Home_project_cover to = {'/LG'} cover={LG_cover} client = 'LG' title = 'LG TV'></Home_project_cover>
                <Home_project_cover className="last" to = {'/Moderna'} cover={Moderna_cover} client = 'Moderna' title = 'Pitch concept'></Home_project_cover>
                    

                </motion.div>
            </div>



        </>
    )
}


export default Home_projects
